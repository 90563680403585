@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    body{
        font-family: 'Poppins', sans-serif;
    }
}

@layer components {
    .center {
        @apply flex items-center justify-center
    }
    .admin_container {
        @apply w-full min-w-[1200px] h-screen overflow-hidden flex;
        background-color: #F7F9FA;
    }

    .sidebar {
        @apply h-full bg-white px-6 py-8 flex flex-col w-80;
        max-width: 350px;
    }

    .active{
        @apply rounded-md text-riverBlue hover:text-white hover:bg-riverBlue;
    }

    .sidenav-item {
        @apply flex text-riverLightBlack items-center px-5 py-3 space-x-5 cursor-pointer
        hover:text-riverBlue hover:rounded-md hover:bg-riverBlue hover:text-white
        transition-all hover:delay-100 inline-block;
    }

    .sideNav_dropItem {
        @apply flex text-riverLightBlack items-center px-5 py-3 space-x-5 cursor-pointer
        hover:text-riverBlue hover:rounded-md
        transition-all hover:delay-100 inline-block;
    }

    .sideNav_dropItem-active {
        @apply flex items-center px-5 py-3 space-x-5 cursor-pointer
        text-riverBlue transition-all hover:delay-100 inline-block;
    }

    .active {
        @apply flex items-center px-5 py-3 space-x-5 cursor-pointer
        text-riverBlue rounded-md bg-riverExtraLightBlue
        transition-all;
    }

    .pip{
        @apply absolute text-white h-3 w-3 -top-0.5 -right-0.5 flex items-center justify-center rounded-full bg-riverRed;
        font-size: 9px;
    }

    .stats {
        @apply bg-white grid grid-cols-3 px-8 py-6 rounded-lg;
    }

    .stats-section {
        @apply flex items-center space-x-10;
    }

    .search-container {
        @apply py-3 px-5 w-1/3 rounded-lg flex items-center space-x-3 bg-gray-100;
    }

    .broker-user_col {
      @apply px-6 py-5 whitespace-nowrap text-sm text-gray-600;
    }

    .broker-user_col-header {
        @apply uppercase px-6 py-5 text-left text-sm text-gray-500 font-normal uppercase tracking-wider;
    }
    .bg-light-blue{
        background-color: #F3FAFD;
    }

    .back-button {
        @apply rounded-full bg-gray-300 flex items-center px-4 py-2 space-x-2 hover:bg-gray-200 transition-all duration-200;
    }

    .back-button__icon{
        @apply inline-block transition-all duration-300;
    }

    .loginBanner {
        background: url("../assets/images/login-bg.png");
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }





}